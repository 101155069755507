export const useStyles = (theme) => ({
    styledMenuContainer: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            color: '#fff',
        },
    },
    button: {
        '& span': {
            textTransform: 'none',
        },
        color: '#fff',
    },
    menuItem: {
        fontFamily: theme?.typography?.primary?.main || '#fff',
    },
});
