import { configureStore } from "@reduxjs/toolkit";
import ApplicationReducer from "@store/slices/application";
import filesReducer from "@store/slices/files";
import alertsReducer from "@store/slices/alerts";
import authorizationReducer from "@store/slices/authorization";

const store = configureStore({
    reducer: {
        application: ApplicationReducer,
        projectFiles: filesReducer,
        alerts: alertsReducer,
        authorization: authorizationReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredPaths: [
                'projectFiles.files',
                'alerts.alertBox',
            ],
          },
        }),
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
export default store;
