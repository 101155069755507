import { useState, type ReactElement } from "react";
import { LoaderSizes } from "@components/Loader/meta";
import { ModalLayouts } from "@layouts/ModalLayout";
import ModalLayout from "@layouts/ModalLayout";
import Loader from "@components/Loader";


const FullScreenLoader = (): ReactElement => {
	const [isModalDialogOpen, setIsModalDialogOpen] = useState<boolean>(true);

	return (
		<ModalLayout isModalLayoutShown={isModalDialogOpen} layoutType={ModalLayouts.LOCKER} handleClose={() => setIsModalDialogOpen(false)} >
			<Loader loaderSize={LoaderSizes.LARGE} />
		</ModalLayout>
	);
};

export default FullScreenLoader;
