import { ResourcesConfig } from "aws-amplify";

export const getAwsConfig = (): ResourcesConfig => {
    // const AWS_IDENTITY_POOL_ID: string | undefined  = process.env.REACT_APP_AWS_IDENTITY_POOL_ID; TODO
    const AWS_USER_POOL_ID: string | undefined  = process.env.REACT_APP_AWS_USER_POOL_ID;
    const AWS_USER_POOL_WEB_CLIENT_ID: string | undefined  = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID;

    if (!AWS_USER_POOL_ID || !AWS_USER_POOL_WEB_CLIENT_ID) {
        throw new Error(`Cannot start the application. Please check the next process.env variables: 
            REACT_APP_AWS_USER_POOL_ID
            REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID`
          );
    }

    return {
        Auth: {
            Cognito: {
            //   identityPoolId: AWS_IDENTITY_POOL_ID,  TODO
              userPoolId: AWS_USER_POOL_ID, 
              userPoolClientId: AWS_USER_POOL_WEB_CLIENT_ID,
            },
        },
    };
}