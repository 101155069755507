import { type ReactElement } from "react";
import { type ITabsData } from "@panels/DeveloperToolsPanel/meta";
import { capitalize } from "@services/textService";
import PreviewAreaTab from "@components/PreviewAreaTab";
import "./style.css";

interface IPreviewExplorerProps {
    setActiveTab: (tabValue: number) => void;
    previewTabs: ITabsData[];
    activeTab: number;
}

const PreviewTabList = ({
    activeTab,
    previewTabs,
    setActiveTab,
}: IPreviewExplorerProps): ReactElement => {
    return (
        <div className="preview-explorer">
            <div className="preview-tabs-container">
                {previewTabs.map((previewTab: ITabsData, index: number) => {
                    return (
                        <PreviewAreaTab
                            key={previewTab.label}
                            tabTitle={capitalize(previewTab.label) as string}
                            isActiveTab={index === activeTab}
                            setActive={() => setActiveTab(index)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PreviewTabList;
