import { IUserFileSerializable, type TUserFiles } from "@domains/File";
import { useState, type ReactElement } from "react";
import { TRootState } from "@store";
import { Themes } from "@domains/Theme";
import { UploadButton } from "@panels/FileIOPanel";
import { UploadButtonTypes } from "@panels/FileIOPanel/meta";
import { replaceFiles, setCurrentFileId } from "@store/slices/files";
import { setIsMainMenuDisplayed, setIsMainMenuDisplayedAtFirst } from "@store/slices/application";
import { ModalLayouts } from "@layouts/ModalLayout";
import UserFile from "@domains/File";
import SvgSpriteIcon from "@components/SvgSpriteIcon";
import ModalDialog from "@components/ModalDialog";
import { useDispatch, useSelector } from "react-redux";
import FilesService from "@services/FilesService";
import ZipService from "@services/ZipService";
import { ProjectTemplateNames } from "./meta";
import "./style.css";

export const enum ButtonTypes {
    STANDARD = "standardButton",
    UPLOAD = "uploadButton",
}

export type TButtonType = ButtonTypes.STANDARD | ButtonTypes.UPLOAD;

export interface IMenu {
    isMenuOpen: boolean;
    onMenuClose: () => void;
}

export interface IMenuButtons {
    key: string;
    type: TButtonType;
    label: string;
    action: () => void;
}

export interface IMainMenuButtonsProps {
    menuButtonsData: IMenuButtons[];
}

export interface IMenuButton {
    svgName: string;
    label: string;
    onClickAction: () => void;
}

export const MenuButton = ({ svgName, label, onClickAction }: IMenuButton) => {
    const appTheme: string = useSelector(
        (state: TRootState) => state.application.currentTheme
    );
    const currentIconColor: string =
        (appTheme === Themes.LIGHT && "grey") || "white";
    const [hoverClassName, setHoverClassName] = useState(currentIconColor);

    return (
        <div
            className={`main-menu-button ${svgName}`}
            onClick={onClickAction}
            onMouseEnter={() => setHoverClassName("black")}
            onMouseLeave={() => setHoverClassName(currentIconColor)}
        >
            <SvgSpriteIcon
                className={`main-menu-button-svg`}
                id={`${svgName}-${hoverClassName}`}
            />
            <span className="main-menu-button-label">{label}</span>
        </div>
    );
};

export const MainMenuButtons = ({
    menuButtonsData,
}: IMainMenuButtonsProps): ReactElement => {
    return (
        <div className="main-menu-buttons">
            {menuButtonsData.map((buttonData) => (
                <div
                    key={buttonData.key}
                    className="main-menu-button-container"
                >
                    {buttonData.type === ButtonTypes.STANDARD && (
                        <MenuButton
                            svgName={buttonData.key}
                            label={buttonData.label}
                            onClickAction={buttonData.action}
                        />
                    )}

                    {buttonData.type === ButtonTypes.UPLOAD && (
                        <UploadButton
                            extraAction={buttonData.action}
                            buttonLabel={buttonData.label}
                            type={UploadButtonTypes.PAINTED}
                            svgName={buttonData.key}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

const MainMenu = (): ReactElement => {
    const isMainMenuDisplayed = useSelector((state: TRootState) => state.application.isMainMenuDisplayed)
    const isMainMenuDisplayedAtFirst = useSelector((state: TRootState) => state.application.isMainMenuDisplayedAtFirst)
    const dispatch = useDispatch();
    // const { createErrorBox } = useAlertsFactory();

    // const serializedFiles = useSelector((state: TRootState) => state.projectFiles.files);
    // const storedFiles: TUserFiles = FilesService.fromSerializableFiles(serializedFiles);

    // const cacheInterval = useSelector(
    //     (state: any) => state.application.cacheInterval
    // );

    // const restoreFromCache = async (): Promise<void> => {
    //     const filesFromCache = await fetchFromCache();
    //     let filesObject: TUserFiles = [];
    //     let fileObject: UserFile;

    //     filesFromCache.forEach((file: any) => {
    //         fileObject = new UserFile();
    //         fileObject.setId(file.id);
    //         fileObject.setName(file.name);
    //         fileObject.setExtension(file.extension);
    //         fileObject.setFullName(file.fullName);
    //         fileObject.setDownloadedState(false);
    //         fileObject.setContent(file.content);
    //         filesObject.push(fileObject);
    //     });

    //     if (filesFromCache.length < 1) {
    //         createErrorBox(
    //             "Cache error",
    //             "There are no saved files in the cache. Create a new project or open example, please."
    //         );
    //         return;
    //     }

    //     const serializableFiles: IUserFileSerializable[] = FilesService.toSerializableFiles(filesObject);
    //     dispatch(replaceFiles(serializableFiles));
    //     handleClose();
    //     await clearCache();
    // };

    // const fetchFromCache = async () => {
    //     const cache = await caches.open(cacheParams.filesRequest);
    //     const keys = await cache.keys();
    //     const files = [];

    //     for (const request of keys) {
    //         const response = await cache.match(request);
    //         if (response) {
    //             const file = await response.json();
    //             files.push(file);
    //         }
    //     }
    //     return files as UserFile[];
    // };

    // const saveFilesToCache = async (): Promise<void> => {
    //     const cache = await caches.open(cacheParams.filesRequest);
    //     storedFiles.forEach((file) => {
    //         const jsonResponse = new Response(JSON.stringify(file), {
    //             headers: cacheParams.header,
    //         });
    //         if (!file.getDownloadedState() || !file.getCachedState()) {
    //             cache.put(
    //                 `${cacheParams.filePath}/${file.getId()}.json`,
    //                 jsonResponse
    //             );
    //             file.setCachedState(true);
    //         }
    //     });
    // };

    // const clearCache = async (): Promise<void> => {
    //     const cacheKeys = await caches.keys();

    //     for (const cacheKey of cacheKeys) {
    //         await caches.delete(cacheKey);
    //     }
    // };

        // const checkIsFirefoxBrowser = (): boolean => {
        //     return navigator.userAgent.toLowerCase().includes(browserNames.FIREFOX);
        // };

    const clearCacheAndCloseModalDialog = async () => {
        handleClose();
        // await clearCache();
    };

    const setFiles = (files: TUserFiles) => {
        const indexFileId: string = files.find((file: UserFile) => file.getName() === "index")!.getId();
        const serializableFiles: IUserFileSerializable[] = FilesService.toSerializableFiles(files);
        
        dispatch(replaceFiles(serializableFiles));
        dispatch(setCurrentFileId(indexFileId));
    };

    const createNewProject = async () => {
        const unpackedFiles = await ZipService.importZipTemplate(ProjectTemplateNames.DRAFT);
        if (!unpackedFiles) {
            throw new Error("Missing files for loading a new project template");
        }
        setFiles(unpackedFiles);
        clearCacheAndCloseModalDialog();
    };

    const openExample = async () => {
        const unpackedFiles = await ZipService.importZipTemplate(ProjectTemplateNames.EXAMPLE_SERVICE);
        if (!unpackedFiles) {
            throw new Error("Files for loading the project template are missing");
        }
        setFiles(unpackedFiles);
        clearCacheAndCloseModalDialog();
        handleClose();
    };

    const handleClose = (): void => {
        dispatch(setIsMainMenuDisplayed(false));
        dispatch(setIsMainMenuDisplayedAtFirst(false));
    };

    const menuButtonsData: IMenuButtons[] = [
        {
            key: "new-project-button",
            type: ButtonTypes.STANDARD,
            label: "New project",
            action: createNewProject,
        },
        {
            key: "open-project-button",
            type: ButtonTypes.UPLOAD,
            label: "Open project",
            action: handleClose,
        },
        // {
        //     key: "restore-project-button",
        //     type: ButtonTypes.STANDARD,
        //     label: "Restore project",
        //     action: restoreFromCache,
        // },
        {
            key: "open-service-button",
            type: ButtonTypes.STANDARD,
            label: "Open example",
            action: openExample,
        },
    ];

    // useEffect(() => {
    //     const loadCache = async () => {
    //         const filesFromCache = await fetchFromCache();
    //     };

    //     loadCache();
    //     window.addEventListener("load", fetchFromCache);

    //     return () => {
    //         window.removeEventListener("load", fetchFromCache);
    //     };
    // }, []);

    // useEffect(() => {
    //     const timerToCacheFiles = setInterval(() => {
    //         saveFilesToCache();
    //     }, cacheInterval * 60000);

    //     return () => {
    //         clearInterval(timerToCacheFiles);
    //     };
    // }, [cacheInterval]);

    // useEffect(() => {
    //     if (!checkIsFirefoxBrowser()) {
    //         const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    //             await saveFilesToCache();
    //             event.preventDefault();
    //         };
    //         window.addEventListener("beforeunload", handleBeforeUnload);

    //         return () => {
    //             window.removeEventListener("beforeunload", handleBeforeUnload);
    //         };
    //     }
    // }, [storedFiles]);

    // useEffect(() => {
    //     if (checkIsFirefoxBrowser()) {
    //         const handleUnload = async (event: BeforeUnloadEvent) => {
    //             await clearCache();
    //             await saveFilesToCache();
    //             event.preventDefault();
    //         };
    //         window.addEventListener("unload", handleUnload);

    //         return () => {
    //             window.removeEventListener("unload", handleUnload);
    //         };
    //     }
    // }, [storedFiles]);

    return (
        <ModalDialog
            isModalDialogOpen={isMainMenuDisplayed || isMainMenuDisplayedAtFirst}
            isCloseButtonShown={isMainMenuDisplayed}
            layoutType={!isMainMenuDisplayedAtFirst ? ModalLayouts.DEFAULT : ModalLayouts.LOCKER}
            handleClose={handleClose}
        >
            <div className="main-menu">
                <div className="main-menu-buttons-wrapper">
                    <MainMenuButtons menuButtonsData={menuButtonsData} />
                </div>
            </div>
        </ModalDialog>
    );
};

export default MainMenu;
