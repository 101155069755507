import { useState } from "react";

import RegistrationForm from "./RegistrationForm";
import VerificationCodeForm from "./VerificationCodeForm";

export interface ISignUp {
  setSignInForm: () => void
}

const SignUp = ( {setSignInForm }: ISignUp) => {
  const [isCodeWaiting, setIsCodeWaiting] = useState<boolean>(false);

  return (
    <div className="signup-main-container form">
      <h2>Sign up for your free account</h2>
        {isCodeWaiting ? (
          <VerificationCodeForm setSignInForm={setSignInForm}/>
        ) : (
          <RegistrationForm setSignInForm={setSignInForm} setIsCodeWaiting={setIsCodeWaiting}/>
        )}
    </div>
  );
};

export default SignUp;
