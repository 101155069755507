import { ChangeEvent, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import snetValidator from "@utils/snetValidator";
import { loginConstraints } from "./validationConstraints";
import useAlertsFactory from "@hooks/useAlertsFactory";
import AuthorizationService from "@services/AuthorizationService";
import { setIsLoading } from "@store/slices/application";
import { setIsUserAuhorized } from "@store/slices/authorization";
import AlertText from "@components/ComponentsLibrary/components/AlertText";
import { InputAdornment } from "@mui/material";
import { eventListenerForKey } from "@utils/listenerForKey";

export interface ILogin {
    setSignUpForm: () => void,
    setForgotPasswordForm: () => void,
}

const Login = ({ setSignUpForm, setForgotPasswordForm }: ILogin) => {
    const dispatch = useDispatch();
    const { createErrorBox } = useAlertsFactory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[] | undefined>();
    const isFilledAllFields: boolean = !!email && !!password;

    const isFormValid = useCallback((): boolean => {
        const isNotValid = snetValidator({ email, password }, loginConstraints);
        
        if (isNotValid) {
            setValidationErrors(isNotValid);
            return false;
        }
        setValidationErrors(undefined);
        return true;
    }, [email, password]);

    useEffect(() => {
        if (!isFilledAllFields) {
            return;
        }
        isFormValid();
    }, [email, password, isFilledAllFields, isFormValid])

    const loginFieldMeta = [
        {id: "email", label: "Email", value: email, setter: setEmail},
        {id: "password", label: "Password", value: password, setter: setPassword}
    ];

    const onInputFormField = (event: ChangeEvent<HTMLInputElement>, setter: { (value: SetStateAction<string>): void; }) => {
        setter(event.target.value);
    }

    const handleSubmit = async () => {
        try {
            dispatch(setIsLoading(true));
            await AuthorizationService.signIn({ email, password })
            dispatch(setIsUserAuhorized(true));
        } catch (error: any) {
            console.error("sign in error: ", error);
            createErrorBox("Something went wrong", error.message); //TODO
        } finally {
            dispatch(setIsLoading(false));
        }
        
    };

    const listener = eventListenerForKey({keyValue: "Enter", callback: handleSubmit, isCtrlPress: true});
    
    useEffect (()=>{
        if (isFormValid()) {
            document.addEventListener('keypress', listener, false);
        } 
        return () => document.removeEventListener('keypress', listener, false);
    }, [isFormValid, listener]);
    
return(
    <div className="login-details form">
        <h2>Welcome Back</h2>
        <form autoFocus className="login-form">
            {loginFieldMeta.map(loginField => (
               <TextField
                    key={loginField.id}
                    id={loginField.id}
                    label={loginField.label}
                    value={loginField.value}
                    type={loginField.id}
                    required
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onInputFormField(event, loginField.setter)}
                    variant="outlined"
                    InputProps={{
                        startAdornment:( <InputAdornment className="dump-for-focus-fields" position="start"/>)
                    }}
               />
            ) )}
            <div className="additional-info">
                <div className="errors-container">
                    {!!validationErrors && isFilledAllFields && validationErrors?.map(validationError => (
                        <AlertText key={validationError} message={validationError} />
                    ))}
                </div>
                <div className="links-section">
                    <div  className="link-to-form" onClick={setForgotPasswordForm}>Forgot password?</div>
                    <div>
                        New to SingularityNET? <span className="link-to-form" onClick={setSignUpForm}>Sign up</span>
                    </div>
                </div>
            </div>
          <Button variant="contained" onClick={handleSubmit} disabled={!!validationErrors || !isFilledAllFields}>login</Button>
        </form>
    </div>
  );
};

export default Login;
