import { ReactElement, useState } from "react";
import SplitBar, { type TPanelWidth } from "@components/SplitBar";
import DeveloperToolsPanel from "@panels/DeveloperToolsPanel";
import CodeEditorPanel from "@panels/CodeEditorPanel";
import "./style.css";

const WorkspacePanels = (): ReactElement => {
	const [panelsWidth, setPanelsWidth] = useState<TPanelWidth>({
		left: "50%",
		right: "50%",
	});

	return (
		<div className="work-space-panels">
			<div className="code-editor-panel" style={{ width: panelsWidth.left }}>
				<CodeEditorPanel />
			</div>
			<SplitBar setPanelWidth={setPanelsWidth} />
			<div
				className="developer-tools-panel"
				style={{ width: panelsWidth.right }}
			>
				<DeveloperToolsPanel />
			</div>
		</div>
	);
};

export default WorkspacePanels;
