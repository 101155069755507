import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSession, setAuthorizationToken, setIsUserAuhorized } from "@store/slices/authorization";
import ModalDialog from "@components/ModalDialog";
import { ModalLayouts } from "@layouts/ModalLayout";
import AuthorizationService from "@services/AuthorizationService";
import { TRootState } from "@store/index";
import Signup from "./Signup";
import "./styles.css";
import Login from "./Login";

import appDumpImage from "@assets/images/appDump.webp";
import ForgotPassword from "./ForgotPassword";
import FullScreenLoader from "@components/FullScreenLoader";

export enum EAuthFormType {
    login = "login",
    signUp = "signUp",
    forgotPassword = "forgotPassword",
}

const Authorization = () => {
    const dispatch = useDispatch();
    const [authFormType, setAuthFormType] = useState<keyof typeof EAuthFormType>(EAuthFormType.login);
    const isUserAuhorized = useSelector((state: TRootState) => state.authorization.isUserAuhorized);
    const isLoading = useSelector((state: TRootState) => state.application.isLoading);

    const setSignUpForm = () => setAuthFormType(EAuthFormType.signUp);
    const setSignInForm = () => setAuthFormType(EAuthFormType.login);
    const setForgotPasswordForm = () => setAuthFormType(EAuthFormType.forgotPassword);

    useEffect(() => {
        const getSession = async () => {
            if (isUserAuhorized) {
                return
            }
            try {
                const idToken = await AuthorizationService.getCurrentAuthSession();
                dispatch(setAuthorizationToken(idToken));
                dispatch(setIsUserAuhorized(true));
            } catch(error) {
                console.error("get session error: ", error);
                dispatch(resetSession(true));
            }
        }
        getSession();
    }, [isUserAuhorized, dispatch]);

    const authorizationFormByType = {
        login: <Login setSignUpForm={setSignUpForm} setForgotPasswordForm={setForgotPasswordForm}/>,
        signUp: <Signup setSignInForm={setSignInForm}/>,
        forgotPassword: <ForgotPassword setSignInForm={setSignInForm}/>
    }

    return (
        <Fragment>
            <ModalDialog
                isModalDialogOpen={!isUserAuhorized}
                layoutType={ModalLayouts.LOCKER}
                children={
                    <div className="authorization-form">
                        {isLoading && <FullScreenLoader /> }
                        {authorizationFormByType[authFormType]}
                    </div>
                }
            />
            <div className="app-dump-container">
                <img src={appDumpImage} alt=""/>
            </div>
        </Fragment>
    );
}

export default Authorization;