interface IEventListenerForKey {
    keyValue: KeyboardEvent["key"],
    isCtrlPress: boolean
    callback: () => void,
}

export const eventListenerForKey = ({ keyValue, callback,  isCtrlPress = false }: IEventListenerForKey) => {
    async function callBackCaller(event: KeyboardEvent){
        if (event?.code === keyValue && event?.ctrlKey === isCtrlPress) {
             await callback(); 
        }
    }
    return callBackCaller
}