import { useState } from "react";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import ForgotPasswordForm from "./ForgotPasswordForm";

export interface IForgotPassword {
    setSignInForm: () => void
}

const ForgotPassword = ({ setSignInForm }: IForgotPassword) => {
    const [isCodeWaiting, setIsCodeWaiting] = useState<boolean>(false);

    return (
        <div className="forgot-password-form form">
            <h2>Forgot your Password?</h2>
            {isCodeWaiting ? 
                (<ForgotPasswordConfirm setSignInForm={setSignInForm}/>) 
                : (<ForgotPasswordForm setIsCodeWaiting={setIsCodeWaiting}/>)
            }
        </div>
    );
};

export default ForgotPassword;
