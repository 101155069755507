export const signupFormConstraints = {
  nickname: { presence: { allowEmpty: false } },
  email: { validEmail: true },
  password: {
    presence: { allowEmpty: false },
    hasLowerCase: true,
    hasUpperCase: true,
    hasNumber: true,
    hasAWSPasswordSplChar: true,
    length: { minimum: 8 },
  },
};

export const singupVerificationCodeContraints = {
  otp: { presence: { allowEmpty: false }, length: { minimum: 6 } },
};

enum EPasswordConstraints {
  lowerCase = "lowerCase",
  upperCase = "upperCase",
  number = "number",
  AWSSplChars = "AWSSplChars",
  length = "length",
}

export type TPasswordContraintNames = keyof typeof EPasswordConstraints;

export const passwordInlineConstraints: {[key in TPasswordContraintNames]: any} = {
  lowerCase: { hasLowerCase: true },
  upperCase: { hasUpperCase: true },
  number: { hasNumber: true },
  AWSSplChars: { hasAWSPasswordSplChar: true },
  length: { length: { minimum: 8 } },
};
