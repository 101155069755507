import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "@store";

import { ModalLayouts } from "@layouts/ModalLayout";
import FullScreenLoader from "@components/FullScreenLoader";
import ModalDialog from "@components/ModalDialog";
import FeedbackForm from "./FeedbackForm";
import OpenFormButton from "./OpenFormButton";
import "./styles.css";

const FeedbackFormModal = () => {
    const isLoading = useSelector((state: TRootState) => state.application.isLoading);
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <Fragment>
            <ModalDialog
                isModalDialogOpen={isModalVisible}
                layoutType={ModalLayouts.LOCKER}
                handleClose={() => setIsModalVisible(false)}
            >
                <FeedbackForm closeForm={() => setIsModalVisible(false)} />
            </ModalDialog>
            {isLoading && <FullScreenLoader /> }
            <OpenFormButton openForm={() => setIsModalVisible(true)} />
        </Fragment>
    )
}

export default FeedbackFormModal;