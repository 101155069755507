const FEEDBACK_ATTACHMENTS_FOLDER = process.env.REACT_APP_FEEDBACK_ATTACHMENTS_FOLDER;
const FEEDBACK_AUTH_TOKEN = process.env.REACT_APP_FEEDBACK_AUTH_TOKEN;
const FEEDBACK_BASE_URL = process.env.REACT_APP_FEEDBACK_BASE_URL;

if (!FEEDBACK_ATTACHMENTS_FOLDER || !FEEDBACK_AUTH_TOKEN || !FEEDBACK_BASE_URL) {
    throw new Error(`Cannot start the application. Please check the next process.env variables: 
        process.env.REACT_APP_FEEDBACK_ATTACHMENTS_FOLDER, process.env.REACT_APP_FEEDBACK_AUTH_TOKEN
        process.env.REACT_APP_FEEDBACK_AUTH_TOKEN, 
        process.env.REACT_APP_FEEDBACK_BASE_URL`
    )
}

export const publishFeedbackAssets = async (fileBlob: File, fileName: string, email: string): Promise<{url: string, datasetKey: string}> => {
    try {
        const fileKey = FEEDBACK_ATTACHMENTS_FOLDER + "/" + email + "_" + Date.now() + "_" + fileName;
        const response = await fetch(FEEDBACK_BASE_URL + "/upload?key=" + fileKey, {
                headers: {
                    Authorization: FEEDBACK_AUTH_TOKEN,
                },
            }
        );

        const { uploadURL } = await response.json();
        await fetch(uploadURL, { method: "PUT", body: fileBlob });
        return  {
            url: `${FEEDBACK_BASE_URL}/download?key=${fileKey}`,
            datasetKey: fileKey,
        };
    } catch (error: any) {
        console.error("publishing feedback assets error: ", error);
        throw new Error(error.message);
    }
};