import { useState, useEffect, useCallback, useMemo } from "react";
import "./styles.css";

interface ITimer {
    endTime: number, //in seconds
    interval: number, //in seconds
    handleTimerCompletion: () => any
}

const Timer = ({ endTime, interval, handleTimerCompletion }: ITimer) => {
    const getTimeNowInSeconds = useCallback((): number => {
        return Math.floor(Date.now() / 1000);
    }, [])

    const endTimeFromNow = useMemo(() => getTimeNowInSeconds() + endTime, [endTime, getTimeNowInSeconds]);

    const [displayTime, setDisplayTime] = useState<number>(endTime);
    const [delay, setDelay] = useState<number | null>(interval * 1000);
  
    useInterval(() => {
        const currentTime = getTimeNowInSeconds();
        if (endTimeFromNow > currentTime) {
            setDisplayTime(endTimeFromNow - currentTime);
        } else {
            setDelay(null);
            handleTimerCompletion();
        }
    }, delay);

    return (
        <div className="timer-container">
            <div className="timer-label-container">
                <p className="number">{displayTime}</p>
                <p className="title">s</p>
            </div>
        </div>
    )
};

function useInterval(callback: () => any, delay: number | null) {
  useEffect(() => {
    function tick() {
        callback();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, callback]);
}

export default Timer;
