import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { forgotPasswordConstraints } from "./validationConstraints";
import snetValidator from "@utils/snetValidator";
import AuthorizationService from "@services/AuthorizationService";
import useAlertsFactory from "@hooks/useAlertsFactory";
import { setUserEmail } from "@store/slices/authorization";
import { setIsLoading } from "@store/slices/application";
import { InputAdornment } from "@mui/material";
import { eventListenerForKey } from "@utils/listenerForKey";

export interface IForgotPasswordForm {
    setIsCodeWaiting: (isCodeWaiting: boolean) => void;
  }

const ForgotPasswordForm = ({ setIsCodeWaiting }: IForgotPasswordForm) => {
    const dispatch = useDispatch();

    const [localEmail, setEmail] = useState<string>("");
    const [validationErr, setValidationErr] = useState<string>("");
    const { createErrorBox } = useAlertsFactory();

    const isEmailValid = useCallback((): boolean => {
        const isNotValid = snetValidator({ email: localEmail }, forgotPasswordConstraints)
        
        if (isNotValid) {
            setValidationErr(isNotValid[0]);
            return false;
        }
        setValidationErr("");
        return true;
    }, [localEmail])

    useEffect(()=>{
        if(!localEmail) {
            return;
        }
        isEmailValid();
    }, [localEmail, isEmailValid])

    const handleSubmit = async () => {
        try {
            dispatch(setIsLoading(true));
            if(!localEmail) {
                createErrorBox("Something went wrong!", "Email is empty");
            }
            await AuthorizationService.resetPassword({email: localEmail});
            dispatch(setUserEmail(localEmail));
            setIsCodeWaiting(true);
        } catch (error: any) {
            createErrorBox("Something went wrong!", error?.message)
        } finally {
            dispatch(setIsLoading(false));
        };
    };

    const listener = eventListenerForKey({keyValue: "Enter", callback: handleSubmit, isCtrlPress: true});
    
    useEffect (()=>{
        if (isEmailValid()) {
            document.addEventListener('keypress', listener, false);
        } 
        return () => document.removeEventListener('keypress', listener, false);
    }, [isEmailValid, listener]);
        

    return (
        <form className="forgot-password-form">
            <TextField
                required
                id="outlined-username-input"
                label="Email"
                type="email"
                name="email"
                variant="outlined"
                value={localEmail}
                error={!!validationErr && !!localEmail}
                helperText={validationErr}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.toLowerCase())}
                InputProps={{
                    startAdornment:( <InputAdornment className="dump-for-focus-fields" position="start"/>)
                }}
            />
            <Button
                color="primary"
                variant="contained"
                children="reset password"
                disabled={!!validationErr || !localEmail}
                onClick={handleSubmit}
            />
        </form>
    );
};

export default ForgotPasswordForm;
